// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

//=================================================================================================================
// environment.demo.ts

export const environment = {
  production: false,
  region: "us-west-2",
  mandatorySignIn: false,
  identityPoolRegion: "us-west-2",
  userPoolId: "us-west-2_gLYNIvDb0",
  clientId: "meaa6rm83k0hmhu8ugl03d6m9",
  apiEndpoint: "https://api.demo.noven.io",
  userPoolWebClientId: "meaa6rm83k0hmhu8ugl03d6m9",
  googleApiKey: "AIzaSyB_GgkiZFx8n3wwEyQg8JYXZus7P016vs8",
  identityPoolId: "us-west-2:1d97cc06-d46c-462d-86f6-527899cf558f",
  saml: {
    provider: "Motus-Employee",
    redirect_uri: "https://www.demo.noven.io/saml",
    url: "https://noven-demo.auth.us-west-2.amazoncognito.com/oauth2/authorize"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import "zone.js/dist/zone-error";
